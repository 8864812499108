/*global _ */

/* DIS/PLAY Script
 Author's name: Anders Gissel
 Modified by:
 Client name: ...
 Date of creation: ...
 */

var dis = dis || {};

(function (dis, underscore) {
	"use strict";


	/**
	 * Using the IIFE, "onScroll" becomes a function that takes a single argument. You can use it from within
	 * your own constructor like so:
	 *
	 *     this.onScroll(function () { });
	 *
	 * This will fire your function every time the window is scrolled (debounced, of course, so it won't
	 * happen too often). If you haven't got a constructor handy, but want to hook into the module anyway,
	 * do this instead:
	 *
	 *     dis.BaseModule.prototype.onScroll(function () { });
	 *
	 * That should accomplish pretty much the same thing.
	 *
	 *
	 * @type {function}
	 */
	dis.BaseModule.prototype.onScroll = (function () {

		var functionQueue = [],
			underscoreThrottle,
			returnedFunction;


		/**
		 * Get the currenct scroll position on both azes.
		 */
		function getScrollPosition() {
			dis.BaseModule.prototype.currentWindowScrollPositionX = dis.BaseModule.prototype.globalDOM.window.scrollLeft();
			dis.BaseModule.prototype.currentWindowScrollPosition = dis.BaseModule.prototype.currentWindowScrollPositionY = dis.BaseModule.prototype.globalDOM.window.scrollTop();
		}


		/**
		 * This function is run every time the window is scrolled. It's on a throttle, though, so it won't be fired too
		 * often.
		 */
		function performCalculationCallback() {
			// Get our current window size(s)
			getScrollPosition();

			underscore.each(functionQueue, function (funcRef) {
				funcRef();
			});
		}

		// Set up a throttle, so our callback function is fired a maximum of once per second.
		underscoreThrottle = underscore.throttle(performCalculationCallback, 500);

		// Utilize our prototyped "ready"-function to bind an event for handling window scroll events.
		dis.BaseModule.prototype.onReady(function () {
			dis.BaseModule.prototype.globalDOM.window.bind("scroll", underscoreThrottle);

			// Get our current scroll positions, because we might need the data.
			getScrollPosition();
		});


		/**
		 * Store a function reference to be fired every time the window is resized.
		 *
		 * @param {function} functionReference
		 */
		returnedFunction = function (functionReference) {
			if (typeof functionReference === "function") {
				functionQueue.push(functionReference);
			}
		};

		// Return a function that can store an incoming function reference and fire it once the window is resized.
		return returnedFunction;

	}());

}(dis, _));