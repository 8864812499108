/*jshint esnext: true */
/*global jQuery, dis, mapReadyCallback, svg4everybody */

/* DIS/PLAY Script
 Author's name: Lars Munkholm
 Modified by:
 Client name: FOA Konkursindeks
 Date of creation: 29-03-2016
 */

(function ($, dis) {
	"use strict";

	function init() {
		dis.inits = {};
		dis.inits.chart = new dis.Chart({ container: ".chart__visual__content", filtersWrapper: ".chart__filters" });

		mapReadyCallback(function() {
			dis.inits.map = new dis.Map({ container: "#map" });
			dis.inits.chart.setMarkers();
		});

		svg4everybody();

		// https://github.com/gfranko/jquery.selectBoxIt.js
		$("select").selectBoxIt();

		// Initialize image handler. Remove this line if you don't need it.
		window.disImageHandler = new dis.ImageHandler();

		// Initialize miscellaneous handler.
		window.disMiscelHandler = new dis.Miscellaneous({ container: "body" });

		// Initialize social sharing handler.
		window.disSocialSharingHandler = new dis.SocialSharing();
	}


	dis.BaseModule.prototype.onReady(init);


}(jQuery, dis));

