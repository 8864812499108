/*jshint esnext: true */
/*global jQuery, dis, TweenMax, Power2 */

/* DIS/PLAY Script
 Author's name: Marlene Thorlund
 Modified by:
 Client name: FOA Konkursindeks
 Date of creation: 25-04-2016
 */


// This closure is where you define which global variables you want. Your naming at THIS point should
// reflect what you want to call your variables inside your closure; their actual name in the global
// scope is referenced at the bottom (during call-time). So go look there as well!
(function ($, dis) {
	"use strict";


	dis.Miscellaneous = function (objectConfiguration) {
		dis.BaseModule.call(this);

		var defaults = {
				animationDuration: 1
			},
			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			eventHandlers,
			handlers,
			activeBox,
			redraw,
			selfScope = this;


		function positionExtendedInfo() {
			// Place extended info under selected box
			var boxesPerRow = eventHandlers.getBoxesPerRow(),
				boxNumberInRow = eventHandlers.getBoxNumberInRow(dom.resultExtended, boxesPerRow),
				placeExtendedInfoAfter = boxesPerRow - boxNumberInRow,
				placeExtendedInfoAfterElement;

			placeExtendedInfoAfterElement = dom.resultExtended.nextAll(":visible:not(.extended-view):lt(" + placeExtendedInfoAfter + "):last");
			if (!placeExtendedInfoAfterElement.length) {
				placeExtendedInfoAfterElement = dom.resultExtended;
			}

			placeExtendedInfoAfterElement.after(dom.extendedInfoLi);

			// Place arrow
			dom.extendedInfoArrow.css("left", dom.resultExtended.position().left + (dom.resultExtended.outerWidth(true) / 2));
		}


		eventHandlers = {
			toggleMap: function(){
				$('.wrapper__main').toggleClass('map-is-displayed');
			},
			getBoxesPerRow: function () {
				var lastPosition,
					boxesPerRow = 0;

				dom.extendedInfoLi.css('display', 'none');

				$(".results li:visible:not(.extended-view)").each(function () {
					var thisPosition = $(this).offset().top;
					if (lastPosition && thisPosition > lastPosition) {
						return false;
					} else {
						lastPosition = thisPosition;
						boxesPerRow += 1;
					}
				});

				dom.extendedInfoLi.css('display', 'block');

				return boxesPerRow;
			},
			getBoxNumberInRow: function (box, boxesPerRow) {
				dom.extendedInfoLi.css('display', 'none');

				var position1 = box.offset().top,
					box2,
					position2,
					box3,
					position3,
					numberInRow = boxesPerRow;

				box2 = box.nextAll("li:visible:not(.extended-view):first");

				if (box2.length) {
					position2 = box2.offset().top;

					if (position2 === position1) {
						numberInRow -= 1;

						box3 = box2.nextAll("li:visible:not(.extended-view):first");

						if (box3.length) {
							position3 = box3.offset().top;

							if (position3 === position2) {
								numberInRow -= 1;
							}
						}
					}
				}

				dom.extendedInfoLi.css('display', 'block');

				return numberInRow;
			},
			closeExtendedInfo: function() {
				dom.resultExtended.removeClass('result--extended');
				dom.extendedInfoLi.removeClass('fade-in');
				TweenMax.to(dom.extendedInfoLi, 0.5, {
					height: 0,
					marginBottom: 0,
					ease: Power2.easeInOut,
					delay: 0.5,
					display: 'none'
				});
			},
			fadeInExtendedView: function() {
				// Make sure extended info fades in (using a forced redraw)
				dom.extendedInfoLi.css('display', 'block');
				redraw = dom.extendedInfoLi[0].offsetHeight;
				dom.extendedInfoLi.addClass('fade-in');
			},
			displayExtendedInfo: function() {
				//change url
				eventHandlers.updateURL();

				// Make sure extended info is hidden (and force redraw)
				dom.extendedInfoLi.css('display', 'none');
				dom.extendedInfoLi.removeClass('fade-in');
				redraw = dom.extendedInfoLi[0].offsetHeight;

				// Position extended info and arrow
				positionExtendedInfo();

				// Show extended info
				TweenMax.to(dom.extendedInfoLi, 0, {
					height: '100%',
					marginBottom: '30px',
					ease: Power2.easeInOut,
					onComplete: eventHandlers.fadeInExtendedView
				});

				dom.clickedBankruptcy.html(dom.extendedInfoLi.clone());

				// Scroll to active bankruptcy box (minus header's height if its position is fixed)
				// If map is showing, scroll to bankruptcy box under map
				var scrollToTarget = (dom.mapToggleSwitch.is(":checked") && selfScope.currentScreenMode > 2 ? dom.clickedBankruptcy.offset().top : activeBox.offset().top);
				if (dom.wrapperHeader.css("position") === "fixed") {
					scrollToTarget -= dom.wrapperHeader.height();
				}

				TweenMax.to($(window), 1, {
					scrollTo: {
						y:scrollToTarget
					},
					ease: Power2.easeInOut
				});

			},
			updateURL: function() {
				var bankruptcyCVR = dom.extendedInfoLi.attr('data-cvr');

				if (history.pushState) {
					var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?cvr=" + bankruptcyCVR;
					window.history.pushState({path:newurl},'',newurl);
				}
			},
			download: function() {
				window.open('/api/getBankruptcy/GetBankruptciesAsCsv', '_blank', '');
			}
		};

		handlers = {
			handleNewEmailRecipient: function(){

				dom.emailForm.submit(function(e){
					e.preventDefault();

					var inputEmail = $('#email').val();
					dom.submitButton = dom.emailForm.find('button[type=submit]');

					//if email address validates in frontend we use ajax to call the backend service SendMail, that takes care of the registration of the emailadresses
					if(handlers.validateEmail(inputEmail)){
						//show spinner while ajax request is being processed
						dom.submitButton.addClass('loading');
						//remove form feedback before applying new after the result of the ajax request
						dom.emailForm.find('span.form__feedback').remove();

						$.ajax({
							method: "GET",
							cache: false,
							url: "/api/getBankruptcy/SendMail",
							data: {
								email: inputEmail
							}
						}).done(function (data) {
							//remove spinner
							dom.submitButton.removeClass('loading');
							//ajax request was a success - a confirmation message is displayed to the user
							dom.emailForm.append('<span class="form__feedback form__feedback--positive">' + data + '</span>');

						}).fail(function (e) {
							//remove spinner
							dom.submitButton.removeClass('loading');
							//ajax request failed - an error message is displayed to the user
							dom.emailForm.append('<span class="form__feedback form__feedback--negative">' + e + '</span>');

						});
					} else {
						dom.emailForm.append('<span class="form__feedback form__feedback--negative">Den indtastede email adresse har et ugyldigt format</span>');
					}


				});
			},
			validateEmail: function(email) {
				var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			}
		};

		/**
		 * Initialization function, which is run when the module is "booting".
		 */
		function init() {
			dom.container = $(configuration.container);

			if (dom.container.length) {

				dom.wrapperHeader = $(".wrapper .wrapper__header");

				dom.mapToggleSwitch = $(".results__top__switch input[type=checkbox]");
				dom.mapToggleSwitch.change(eventHandlers.toggleMap);

				dom.extendedInfoLi = $('.extended-view');
				dom.extendedInfoArrow = dom.extendedInfoLi.find(".extended-view__arrow");

				dom.closeExtendedInfo = dom.extendedInfoLi.find('.close');
				dom.closeExtendedInfo.click(eventHandlers.closeExtendedInfo);

				dom.clickedBankruptcy = $(".map-container .clicked-bankruptcy");

				$("body").on('click', ".map-is-displayed .extended-view .close", function () {
					selfScope.globalDOM.body.find('.extended-view').removeClass('fade-in');
					TweenMax.to(selfScope.globalDOM.body.find('.extended-view'), 0.5, {
						height: 0,
						marginBottom: 0,
						ease: Power2.easeInOut,
						delay: 0.5,
						display: 'none'
					});
				});

				$("body").on('click', ".results > ul > li:not(.extended-view)", function () {
					activeBox = $(this);

					if (dom.resultExtended) {
						dom.resultExtended.removeClass('result--extended');
					}

					dom.resultExtended = $(this);
					dom.resultExtended.addClass('result--extended');
					eventHandlers.displayExtendedInfo();
				});

				// Reposition extended view on window resize if it's visible
				selfScope.onWindowResize(function () {
					if (dom.extendedInfoLi.is(":visible")) {
						positionExtendedInfo();
					}
				});

				dom.emailForm = selfScope.globalDOM.body.find("#submitEmailForm");
				dom.emailInputField = dom.emailForm.find("#email");
				if(dom.emailForm){
					handlers.handleNewEmailRecipient();
					dom.emailInputField.focus(function(){
						dom.emailForm.find('span.form__feedback').remove();
					});
				}

				//download
				dom.downloadButton = selfScope.globalDOM.body.find("#download");
				dom.downloadButton.click(function(e){
					e.preventDefault();
					eventHandlers.download();
				});

				//test animation - drawSVG
				dom.triggerButton = selfScope.globalDOM.body.find('#loading-curve__trigger');
				TweenMax.staggerFromTo("#pathToAnimate",0,{drawSVG:"0 100%"},{drawSVG:"100% 100%"},0);
				dom.triggerButton.click(function(){
					//TweenMax.fromTo("#pathToAnimate" , 1, {drawSVG:"100% 100%"} , {drawSWG:"0% 100%", ease:Power2.easeOut});
					TweenMax.staggerFromTo("#pathToAnimate",0.8,{drawSVG:"100% 100%"},{drawSVG:"0 100%", ease:Power2.easeOut},0.5);
				});
			}
		}

		selfScope.onReady(init);

	};

	dis.Miscellaneous.prototype = new dis.BaseModule();
	dis.Miscellaneous.constructor = dis.Miscellaneous;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));