/*jshint esnext: true */
/*global jQuery, dis */

/* DIS/PLAY Script
 Author's name: ...
 Modified by:
 Client name: ...
 Date of creation: ...
 */


// This closure is where you define which global variables you want. Your naming at THIS point should
// reflect what you want to call your variables inside your closure; their actual name in the global
// scope is referenced at the bottom (during call-time). So go look there as well!
(function ($, dis) {
	"use strict";


	/**
	 * This module is an example of how you might build a module for the site. Use code as needed, and then remove from
	 * your development project!
	 *
	 *
	 * ****************************************************************************************************************
	 * ****************************************************************************************************************
	 * ****************************************************************************************************************
	 *
	 * DO *NOT* JUST COPY THIS FILE AND CHANGE A FEW LINES HERE AND THERE!
	 *
	 * Prototyping inheritance is used on this constructor, which means you can't just blindly steal without altering
	 * at least the constructor- and prototype definitions at the bottom. It's YOUR responsibility to ensure that
	 * your inheritance chain works properly!
	 *
	 * AGAIN: DO NOT JUST COPY/PASTE. This is an example. Nothing more.
	 *
	 * ****************************************************************************************************************
	 * ****************************************************************************************************************
	 * ****************************************************************************************************************
	 *
	 *
	 *
	 * @param objectConfiguration - The configuration for the object
	 * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
	 * @constructor
	 * @extends dis.BaseModule
	 */
	dis.ExampleModule = function (objectConfiguration) {

		// Fire the basemodule-initiator on this module.
		dis.BaseModule.call(this);

		// First, set up your "global" vars for use inside this function. JSLint prefers you bunch up all your
		// var-statements in one single block, so that's what we're doing here. This also gives you the added
		// benefit of easily being able to see which variables are unused, if your editor supports that kind
		// of thing.

		// "defaults" should contain your default settings. These will be used unless overridden by something else when
		// the module is loaded.
		var defaults = {
				SomeValue: "This is an example that can be overridden by passing {SomeValue: 'Something else'} to the constructor."
			},

		// This is where we define the actual configuration for this instantiation. We use jQuery's extend(), which
		// lays "objectConfiguration" on top of "defaults", the result of which is put into "configuration". You can then
		// use "configuration.SomeValue" wherever you need it in this script.
		// See jQuery's docs about this function if you need to pass in objects, like you would for labels or similar.
			configuration = $.extend(defaults, objectConfiguration),

		// Set up an empty object that will contain our DOM elements later. This is done to minimize the number of times
		// jQuery has to look through the DOM to find the element you want to work on.
			dom = {},

		// This is an empty placeholder that we will populate with functions later. It's only here for example-reasons.
			eventHandlers,

		// Reference to the current scope, usable inside callbacks et al. to reference this module (instead of whatever
		// scope the function might be running in.) You will not realize the awesomeness of this until you have experienced
		// the hell that is scope-confusion in javascript, so for now just go with it. Basically, you can use "selfScope"
		// instead of "this" when you need to refer to this module instantiation.
			selfScope = this;


		// This is an example of how you might bundle together "private" functions that relate to each other. In this
		// case, we'll gather all event handlers in the same object so they're easily referenced later.
		eventHandlers = {

			/**
			 * An example of a refresh-action.
			 */
			refreshAll: function () {
				window.console.log("Performing refresh-action...");
				// Perform any action here, such as:
				// $.ajax({...})
			},


			/**
			 * An example of a click handler.
			 *
			 * @param {Event} e
			 */
			someClickEvent: function (e) {
				e.preventDefault();
			}

		};


		/**
		 * This function is "private", which means it will only be accessible from inside this module. You can run it
		 * from any point by running thisIsAPrivateFunction(), but ONLY inside this instantiation.
		 *
		 * @param {string} someString - Required string containing the name of your favorite beer.
		 * @param {Array} [someArray] - Optional array of stuff.
		 */
		function thisIsAPrivateFunction(someString, someArray) {
			window.console.log(someString);

			if (someArray instanceof Array && someArray.length) {
				window.console.log(someArray.join(", "));
			}
		}


		/**
		 * This function is "public" (notice "this."), which means that once your module is instantiated, it will expose
		 * this function as a usable function, like so:
		 *
		 *      var example = new dis.ExampleModule({ container: $('#whatever' });
		 *      example.someFunction();
		 *
		 * Not only that, but because this function returns "selfScope" at the end, you're able to chain together more
		 * functions if you have them, like so:
		 *
		 *      example.someFunction().someOtherFunction();
		 *
		 * These two functions will now run in succession.
		 *
		 *
		 * @returns {dis.ExampleModule}
		 */
		this.someFunction = function () {

			// Run an internal function. We can do this because although this function is public, it resides inside the
			// same scope as the private function does. So we have direct access to everything, even though the "outside"
			// can never touch it. Neat, huh?
			thisIsAPrivateFunction("Indian Pale Ale");

			// Return the "selfScope", as explained in the comment block above.
			return selfScope;
		};


		/**
		 * Initialization function, which is run when the module is "booting".
		 */
		function init() {

			// Get and store the DOM-reference to the container we've been given.
			dom.container = $(configuration.container);

			if (!dom.container.length) {
				// If we haven't got a container at this point, throw a hissy fit!
				throw "Given container could not be found, or no container given. That's bad!";
			}


			// At this point we know that at least the container exists, so now we can map all the objects
			// we'll need inside it. Such as this button:
			dom.submitButton = dom.container.find("[type=submit]");
			// Bind an internal click handler to the button.
			dom.submitButton.click(eventHandlers.someClickEvent);


			// Do you want to get the current window width, height, or media query state? Easy. Use any of these variables:
			// selfScope.currentWindowWidth  = the current width of the window, in pixels (UNSAFE FOR MEDIA MATCHING!)
			// selfScope.currentWindowHeight = the current height of the window, in pixels (UNSAFE FOR MEDIA MATCHING!)
			// selfScope.currentScreenMode   = the current screen mode, using media queries. This will be an integer that you can match thusly:
			//
			//      selfScope.currentScreenMode >= selfScope.screenSizes.mobile        // at least a mobile device (always true)
			//      selfScope.currentScreenMode >= selfScope.screenSizes.largeMobile   // at least a large mobile device (defaults to 540px)
			//      selfScope.currentScreenMode >= selfScope.screenSizes.tablet        // at least a tablet (defaults to 768px)
			//      selfScope.currentScreenMode >= selfScope.screenSizes.desktop       // at least a desktop (defaults to 1024px)
			//      selfScope.currentScreenMode >= selfScope.screenSizes.largeDesktop  // at least a large desktop (defaults to 1280px)
			//      selfScope.currentScreenMode >= selfScope.screenSizes.veryLargeDesktop  // at least a VERY large desktop (defaults to 1600px)
			//
			// The basic matching scripting is inside dis.base.js, and the widths used are defined in main.scss.


			// Do you want to perform an action when the window has been resized? Easy. Do this:
			selfScope.onWindowResize(eventHandlers.refreshAll);
			// or: selfScope.onWindowResize(function () {});
			// The function you specify will only be fired once per second, so it's safe to use heavy calculations inside it.


			// Need access to <body> or the window? Easy. Use either of these:
			// selfScope.globalDOM.body     - jQuery-wrapped <body>, ready to use.
			// selfScope.globalDOM.window   - jQuery-wrapped window, ready to use.

			selfScope.globalDOM.body.addClass("hest");


			// ****************************************************************************************************
			// A word about events:
			// ****************************************************************************************************


			// Since we're inside a unique scope, and we have a DOM-reference to work with, we can bind custom
			// events as well as regular ones. In this case, we'll run the "refresh"-action when the "dis.refresh"
			// event is run:
			dom.container.on("dis.refresh", eventHandlers.refreshAll);


			// You can now trigger the event from any script across the site like so:
			//
			//      $("#whatever-element").trigger("dis.refresh");
			//
			// or
			//
			//      $("section.some-class").trigger("dis.refresh");
			//
			// I recommend you look into this article, to see how you can pass arguments to the event callbacks:
			// https://api.jquery.com/trigger/


			// If you need to access this module later, and you want to be sure you can always dig up this scope
			// again, you could do this:
			//
			//      dom.container.data("disScope", selfScope);
			//
			// After that, any script in your stack can do this:
			//
			//      var exampleCode = $('#someElement').data("disScope");
			//      exampleCode.someFunction();
			//
			// But I would recommend you use an event-driven design, as explained above, instead.

		}

		// Once everything is ready, run the init-function to get the ball rolling. The "onReady()" function lives
		// in dis.base.js.
		selfScope.onReady(init);

	};

	dis.ExampleModule.prototype = new dis.BaseModule();
	dis.ExampleModule.constructor = dis.ExampleModule;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));