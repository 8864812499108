/* DIS/PLAY Script
 Author's name: Lars Munkholm
 Modified by:
 Client name: ...
 Date of creation: ...
 */

var dis = dis || {};

(function (dis) {
	"use strict";


	/**
	 * Scroll lock returns 4 functions.
	 * Enable, disable and toggle are pretty self explanatory.
	 * State returns the current state as a boolean. True means enabled, false means disabled.
	 *
	 * @type {{enable, disable, toggle, state}}
	 */
	dis.BaseModule.prototype.scrollLock = (function () {
		// className is the class added to the body when scroll lock is enabled.
		// This is not used for anything by default, but is added anyway, in case styling and transitions are needed.
		var className = "dis-scroll-lock",
			scrollTop,
			state = false,
			eventHandlers;

		// Event handlers
		eventHandlers = {
			enable: function () {
				// Enable scroll lock
				// ------------------

				if (!state) {
					// Get scroll position
					scrollTop = dis.BaseModule.prototype.currentWindowScrollPositionY;

					// Reset scroll position
					dis.BaseModule.prototype.globalDOM.window.scrollTop(0);

					dis.BaseModule.prototype.globalDOM.html
						// Remember scroll position
						.data("dis-scroll-top", scrollTop)

						// Add class to body
						.addClass(className)

						// Set styling, e.g. negative top-margin to accommodate for the resetting of the scroll position
						.css({
							"margin-top": -scrollTop,
							"position": "fixed",
							"overflow": "hidden",
							"width": "100%"
						});

					// Trigger event on target
					dis.BaseModule.prototype.globalDOM.body.trigger("dis.scrolllock:enable");

					// Remember state
					state = true;
				}
			},
			disable: function () {
				// Disable scroll lock
				// -------------------

				if (state) {
					// Get stored scroll position from data attribute
					scrollTop = dis.BaseModule.prototype.globalDOM.html.data("dis-scroll-top");

					dis.BaseModule.prototype.globalDOM.html
						// Reset the data attribute
						.data("dis-scroll-top", null)

						// Remove class from body
						.removeClass(className)

						// Remove styling
						.css({
							"margin-top": "",
							"position": "",
							"overflow": "",
							"width": ""
						});

					// Set the scroll position to what it was before
					dis.BaseModule.prototype.globalDOM.window.scrollTop(scrollTop);

					// Trigger event on target
					dis.BaseModule.prototype.globalDOM.body.trigger("dis.scrolllock:disable");

					// Remember state
					state = false;
				}
			}
		};

		// Return object of functions
		return {
			enable: eventHandlers.enable,
			disable: eventHandlers.disable,
			toggle: function () {
				if (state) {
					eventHandlers.disable();
				} else {
					eventHandlers.enable();
				}
			},
			state: function () {
				return state;
			}
		};
	}());

}(dis));