/*jshint esnext: true */
/*global jQuery, dis*/

/* DIS/PLAY Script
 Author's name: Marlene Thorlund
 Modified by:
 Client name: FOA Konkursindeks
 Date of creation: 03-05-2016
 */


// This closure is where you define which global variables you want. Your naming at THIS point should
// reflect what you want to call your variables inside your closure; their actual name in the global
// scope is referenced at the bottom (during call-time). So go look there as well!
(function ($, dis) {
	"use strict";


	dis.SocialSharing = function () {
		dis.BaseModule.call(this);

		var defaults = {
				fbAppId: "1724671507749286",
				pageShareTitle: "Konkursindeks - registrerede konkurser i Ældreplejen",
				pageShareImg: "static/dist/img/konkursindex.png",
				pageShareNameFacebook: "Konkursindex viser dig, hvor konkurser i hjemmeplejen rammer danskerne.",
				pageShareDescriptionFacebook: "Se om din kommune er registreret. Læs hvor mange ældre, der blev ramt. DEL projektet og hjælp med at sætte fokus på, at man ikke skal gamble med velfærden for vores svage og ældre."
			},
			dom = {},
			eventHandlers,
			handlers,
			selfScope = this;


		eventHandlers = {
			openPopup: function (url) {
				var width = 575,
					height = 400,
					left = (selfScope.globalDOM.window.width() - width) / 2,
					top = (selfScope.globalDOM.window.height() - height) / 2,
					opts = "status=1" +
						",width=" + width +
						",height=" + height +
						",top=" + top +
						",left=" + left;

				window.open(url, 'socialsharer', opts);

				return false;
			}
		};

		handlers = {

		};

		/**
		 * Initialization function, which is run when the module is "booting".
		 */
		function init() {

			// Share on Facebook - PAGE
			//------------------
			dom.facebookPageLink = selfScope.globalDOM.body.find('.facebook__link');
			dom.facebookPageLink.click(function(e){
				e.preventDefault();

				var name,
					description,
					picture,
					link,
					shareUrl = "https://www.facebook.com/dialog/feed?app_id=" + defaults.fbAppId + "&display=popup&locale=da_DK";

				name = defaults.pageShareNameFacebook;
				shareUrl += "&name=" + encodeURIComponent(name);

				description = defaults.pageShareDescriptionFacebook;
				shareUrl += "&description=" + encodeURIComponent(description + " ");

				// Get page url
				link = window.location.href;

				// Get absolute path to picture and add it to share URL
				picture = link + defaults.pageShareImg;
				shareUrl += "&picture=" + encodeURIComponent(picture);

				shareUrl += "&link=" + encodeURIComponent(link);

				eventHandlers.openPopup(shareUrl);
			});




			// Share on Facebook - BANKRUPTCY
			//------------------
			$("body").on('click', ".facebook__link--bankcruptcy", function (e) {
				e.preventDefault();

				var clicked = $(this),
					link,
					shareUrl = "https://www.facebook.com/dialog/feed?app_id=" + defaults.fbAppId + "&display=popup&locale=da_DK";

				// Get link and add it to share URL (with bankruptcyId as a parameter, so the shareurl is uniq and so we can deeplink to a single bankruptcy)
				link = window.location.href;
				shareUrl += "&link=" + encodeURIComponent(link);

				// TRIGGER SHARE POPUP
				//---------------------------
				eventHandlers.openPopup(shareUrl);

			});



			// Share on Twitter - PAGE
			//-----------------
			dom.twitterSharePageTrigger = selfScope.globalDOM.body.find('.twitter__link');
			dom.twitterSharePageTrigger.click(function(e){
				e.preventDefault();

				var link,
					text,
					shareUrl = "https://twitter.com/intent/tweet?url=";

				//add text to shareUrl
				text = defaults.pageShareTitle;
				shareUrl += "&text=" + encodeURIComponent(text + " ");

				//add link to shareUrl
				link = window.location.href;
				shareUrl += encodeURIComponent(link);

				eventHandlers.openPopup(shareUrl);
			});



			// Share on Twitter - BANKRUPTCY
			//-----------------
			$("body").on('click', ".twitter__link--bankcruptcy", function (e) {
				e.preventDefault();

				var clicked = $(this),
					liExtendedElement = clicked.parents('li.extended-view'),
					bankruptcyId,
					link,
					companyName,
					dateOfBankruptcy,
					numberOfCitizens,
					text,
					shareUrl = "https://twitter.com/intent/tweet?url=";

				bankruptcyId = liExtendedElement.data('id');

				// Get title and text and add it to share URL
				companyName = liExtendedElement.find("h2:first").text().trim();
				dateOfBankruptcy = liExtendedElement.find(".bankruptcy-date").text().trim();

				//get number of citizens affected
				numberOfCitizens = liExtendedElement.find(".affected-citizens").text().trim();
				text = "Firmaet " + companyName + " gik konkurs " + dateOfBankruptcy + ". Konkursen berørte samlet set " + numberOfCitizens + " borgere.";


				shareUrl += "&text=" + encodeURIComponent(text + " ");

				// Get link and add it to share URL (with bankruptcyId as a parameter, so the shareurl is uniq and so we can deeplink to a single bankruptcy)
				link = window.location.href; //no bankruptcy-id added to shareUrl on twitter
				shareUrl += encodeURIComponent(link);

				eventHandlers.openPopup(shareUrl);
			});

		}

		selfScope.onReady(init);

	};

	dis.SocialSharing.prototype = new dis.BaseModule();
	dis.SocialSharing.constructor = dis.SocialSharing;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));