/*jshint esnext: true */
/*global jQuery, dis, google, MarkerClusterer */

/* DIS/PLAY Script
 Author's name: Marlene Thorlund
 Modified by:
 Client name: FOA Konkursindeks
 Date of creation: 20-04-2016
 */


// This closure is where you define which global variables you want. Your naming at THIS point should
// reflect what you want to call your variables inside your closure; their actual name in the global
// scope is referenced at the bottom (during call-time). So go look there as well!
(function ($, dis) {
	"use strict";


	dis.Map = function (objectConfiguration) {
		dis.BaseModule.call(this);

		var defaults = {
				mapOptions: {
					zoom: 7,
					center: {lat: 56.26392, lng: 9.501785},
					mapTypeId: google.maps.MapTypeId.ROADMAP
				},
				mapIcon: '/static/dist/img/map-icon.svg',
				mapStyles: [
					{
						"featureType": "water",
						"stylers": [
							{ "color": "#000000" }
						]
					},{
						"featureType": "landscape",
						"stylers": [
							{ "color": "#3F3E3E" }
						]
					},{
						"featureType": "road.arterial",
						"stylers": [
							{ "visibility": "off" }
						]
					},{
						"featureType": "administrative",
						"stylers": [
							{ "color": "#ffffff" },
							{ "visibility": "simplified" }
						]
					},{
						"featureType": "poi",
						"stylers": [
							{ "color": "#3f3f3f" }
						]
					},{
						"featureType": "road",
						"stylers": [
							{ "color": "#6E6259" },
							{ "visibility": "on" }
						]
					},{
						"featureType": "transit",
						"stylers": [
							{ "visibility": "off" }
						]
					}
				],

				mapMarkers: []
			},
			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			eventHandlers,
			handlers,
			markers = [],
			iconBase = location.protocol + "//" + location.host + '/static/dist/img/',
			selfScope = this;


		selfScope.setClusters = function() {
			dom.markerCluster = new MarkerClusterer(dom.map, markers, {
				maxZoom: 11,
				styles: [
					{
						height: 30,
						url: iconBase + 'maps-cluster.png',
						width: 30
					}
				]
			});
		};


		selfScope.setMarkers = function(points) {
			if (points && points.length) {
				points.forEach(function (point) {
					var marker = new google.maps.Marker({
						position: {
							lat: parseFloat(point.Latitude),
							lng: parseFloat(point.Longitude)
						},
						map: dom.map,
						id: point.ItemId,
						title: point.CompanyName,
						icon: iconBase + 'maps-pin.png'
					});

					marker.addListener('click', function () {
						var itemId = $(this)[0].id;

						$(".results > ul > li[data-id=" + itemId + "]").click();
					});

					markers.push(marker);
				});
			}
		};


		selfScope.clearMarkers = function() {
			var markersAmount = markers.length;

			if (markersAmount) {
				if (dom.markerCluster) {
					dom.markerCluster.clearMarkers();
				}

				for (var i = 0; i < markersAmount; i += 1) {
					markers[i].setMap(null);
				}

				markers.length = 0;
			}
		};


		eventHandlers = {};


		handlers = {
			initMap: function () {
				dom.mapDiv = $('#map');
				dom.map = new google.maps.Map(dom.mapDiv.get(0), configuration.mapOptions);
				dom.map.set('styles', configuration.mapStyles);
			},
			addMarker: function (myLatLng) {
				dom.marker = new google.maps.Marker({
					position: myLatLng,
					map: dom.map,
					icon: configuration.mapIcon
				});
				configuration.mapMarkers.push(dom.marker);
			}
		};


		/**
		 * Initialization function, which is run when the module is "booting".
		 */
		function init() {
			dom.container = $(configuration.container);

			if (dom.container.length) {

				handlers.initMap();

				//TODO: add function to cycle through marker positions and adding marker to marker array
				//handlers.displayClusters(configuration.mapMarkers);

			}
		}

		selfScope.onReady(init);

	};

	dis.Map.prototype = new dis.BaseModule();
	dis.Map.constructor = dis.Map;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));