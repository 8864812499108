/*jshint esnext: true */
/*global jQuery, dis, TweenMax, Power2, _ */

/* DIS/PLAY Script
 Author's name: Lars Munkholm
 Modified by:
 Client name: FOA Konkursindeks
 Date of creation: 29-03-2016
 */


// This closure is where you define which global variables you want. Your naming at THIS point should
// reflect what you want to call your variables inside your closure; their actual name in the global
// scope is referenced at the bottom (during call-time). So go look there as well!
(function ($, dis, underscore) {
	"use strict";


	dis.Chart = function (objectConfiguration) {
		dis.BaseModule.call(this);

		var defaults = {
				ajaxUrl: "/api/getBankruptcy/GetBankruptcies/DC49ED86-8587-467E-BB66-9CEA5E5B4E8D", // URL for AJAX-service
				showYear: 0,
				region: "",
				municipality: "",
				classNames: {
					animateIn: "animate-in",
					animateOut: "animate-out",
					animateQuick: "animate-quick",
					pointDefault: "chart__visual__point",
					pointBullet: "chart__visual__point--bullet",
					pointClickable: "chart__visual__point--clickable"
				},
				chartOptions: {
					height: "110px",
					lineSmooth: false,
					showArea: true,
					showLine: false,
					fullWidth: true,
					showLabel: false,
					axisX: {
						showGrid: false,
						showLabel: false,
						offset: 0
					},
					axisY: {
						showGrid: false,
						showLabel: false,
						offset: 0
					},
					chartPadding: 0,
					low: 0
				},
				numberOptions: {
					activeCvrs: [],
					animationDuration: 2
				},
				boxValues: {
					missingContentLabel: "Ukendt",
					isFocus: false,
					itemId: '',
					imgSmall: '',
					imgLarge: '',
					DateOfBankruptcyFormatted: '',
					CompanyName: '',
					CVR: '',
					ConcernedCitizensTotal: 0,
					ConcernedCitizensCounty: 0,
					ConcernedEmployeesTotal: 0,
					ConcernedEmployeesCounty: 0,
					County: '',
					CountyId: '',
					RegionId: '',
					IsFocusBankruptcy: false
				}
			},
			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			chart,
			chartDrawsCount = 0,
			eventHandlers,
			sorting,
			handlers,
			imagehandler,
			mapData = [],
			municipalitiesInRegion = {},
			selectBoxValues = {},
			masterData = [],
			totalBankruptcies,
			totalCitizens = 0,
			totalEmployees = 0,
			//totalBoxNum = 0,
			boxDom = "",
			totalBoxObjects = {},
			filteringIteration = 0,
			selfScope = this;


		// Format a string with date and time.
		// Input must look like this: "20160328T220000Z"
		function formatDatetime(datetime) {
			var year = parseInt(datetime.substr(0, 4)),
				month = parseInt(datetime.substr(4, 2)),
				day = parseInt(datetime.substr(6, 2)),
				hours = parseInt(datetime.substr(9, 2)),
				minutes = parseInt(datetime.substr(11, 2)),
				seconds = parseInt(datetime.substr(13, 2)),
				dateObject = new Date(year, month, day, hours, minutes, seconds),
				timestamp = dateObject.getTime();

			return {
				date: {
					day: day,
					month: month,
					year: year
				},
				time: {
					hours: hours,
					minutes: minutes,
					seconds: seconds
				},
				timestamp: timestamp,
				dateObject: dateObject
			};
		}

		selfScope.setMarkers = function (markerData) {
			markerData = markerData || mapData;

			if (dis.inits.map && markerData) {
				dis.inits.map.clearMarkers();
				dis.inits.map.setMarkers(markerData);
				dis.inits.map.setClusters();
			}
		};

		function filterData(callback) {
			var chartData = {
					labels: [],
					series: []
				},
				selectedKey,
				resultat;

			mapData = [];

			// Hide extended info view
			dom.extendedInfoLi.css('display', 'none');

			//numbers must be reset before new calculation based on the new selection
			totalCitizens = 0;
			totalEmployees = 0;
			totalBankruptcies = 0;

			//this returns all the entries that match certain conditions to the resultat obj. So the resultat is like masterData but only containing the entries that match the current filtering
			resultat = underscore.filter(masterData, function(entry) {
				//if the current active region (configuration.region) is not matching the entry region, then the entry is not added to resultat
				if (configuration.region && entry.RegionId !== configuration.region) {
					return false;
				}

				//if the current active year (configuration.showYear) is not matching the entry year, then the entry is not added to resultat
				if (configuration.showYear && configuration.showYear !== entry.parsedBankruptcyDate.date.year) {
					return false;
				}

				//if the current active municipality (configuration.municipality) is not matching the entry municipality, then the entry is not added to resultat
				if (configuration.municipality && entry.CountyId !== configuration.municipality) {
					return false;
				}

				return true;
			});

			filteringIteration += 1;
			if (filteringIteration > 1) {
				// If boxes are already shown for the first time
				eventHandlers.displayBoxes();
			}

			//--------------------------------------------
			//calculate Numbers to display in top of page
			//--------------------------------------------
			//reset active cvr array before creating a new array of active cvr based on the new selection made by the user
			defaults.numberOptions.activeCvrs = [];

			underscore.each(resultat, function(entry) {

				//in all, not only uniq companies we calculate the total concerned citizens
				//we check if value is a number before adding up, while the calculation will fail otherwise
				if ($.isNumeric(entry.ConcernedCitizensCounty)) {
					totalCitizens += parseInt(entry.ConcernedCitizensCounty);
				}

				//create array with uniq companies based on cvr. nr.
				if (defaults.numberOptions.activeCvrs.indexOf(entry.Cvr) < 0) {
					defaults.numberOptions.activeCvrs.push(entry.Cvr);

					//in the uniq companies we calculate the total concerned employees
					//we check if value is a number before adding up, while the calculation will fail otherwise
					if ($.isNumeric(entry.ConcernedEmployeesTotal)) {
						totalEmployees += parseInt(entry.ConcernedEmployeesTotal);
					}
				}
			});

			totalBankruptcies = defaults.numberOptions.activeCvrs.length;

			//----------------------------------------------


			if (configuration.municipality) {
				(function () {
					var dates = {};

					underscore.each(resultat, function(entry) {
						var dato = formatDatetime(entry.DateOfBankruptcy).date,
							parentFormateretDato = dato.day + "." + dato.month + "." + dato.year.toString().substr(2);

						dates[parentFormateretDato] = 1;

						mapData.push(entry);
					});


					chartData.labels = underscore.keys(dates);
					chartData.series = [new Array(chartData.labels.length)];

					//add class to wrapper__main to control display of area specific content in boxes
					$(".wrapper__main").addClass('municipality--selected');
				}());
			} else {
				selectedKey = configuration.region ? "County" : "Region";

				(function () {
					var collection = {};

					underscore.each(resultat, function (entry) {
						var currentKey = entry[selectedKey];

						if (!collection[currentKey]) {
							collection[currentKey] = 1;
						} else {
							collection[currentKey] += 1;
						}

						mapData.push(entry);
					});

					chartData.labels = underscore.keys(collection);
					chartData.series.push(underscore.values(collection));

					//remove class to wrapper__main to control display of area specific content in boxes
					$(".wrapper__main").removeClass('municipality--selected');
				}());
			}

			callback(chartData);

			selfScope.setMarkers(mapData);
		}

		function parseData(data) {
			// Function for parsing the data to prepare them for the chartist plugin
			// This requires one parameters: data in json format.
			// The date property is transformed to another format via the function formatDatetime()
			// The data is parsed and saved in the masterData Object

			//an array to contain the ids of the active Municipalities - this be the municipalities that actually has bankcruptcies
			var activeMunicipalities = [],
				activeYears = [];


			/*
			* the function loops through all bankcrupties (each entry) in the data
			* */
			underscore.each(data, function(entry) {
				entry.parsedFoundedDate = formatDatetime(entry.FoundedDate).date;
				entry.parsedBankruptcyDate = (entry.parsedBankruptcyDate || entry.parsedBankruptcyDate !== null || entry.parsedBankruptcyDate !== undefined || entry.parsedBankruptcyDate !== "") ? formatDatetime(entry.DateOfBankruptcy) : null;


				masterData.push(entry);
				//the values in selects are wrapped in {}, so we add this before pushing the CountyId to active array
				activeMunicipalities.push("{" + entry.CountyId + "}");

				if (entry.parsedBankruptcyDate !== null && activeYears.indexOf(entry.parsedBankruptcyDate.date.year) < 0) {
					activeYears.push(entry.parsedBankruptcyDate.date.year);
				}

				//if the regionId on a certain entry is not present in the object municipalitiesInRegion it must be added before adding the CountyId to the object
				if(!municipalitiesInRegion[entry.RegionId]){
					municipalitiesInRegion[entry.RegionId] = [];
				}

				//CountyId is added under the corresponding Region
				municipalitiesInRegion[entry.RegionId].push(entry.CountyId);


				if(!totalBoxObjects[entry.ItemId]){
					totalBoxObjects[entry.ItemId] = [];
				}

				defaults.boxValues.itemId = entry.ItemId;
				defaults.boxValues.imgSmall = entry.ImagePath + '?h=250&w=320';
				defaults.boxValues.imgLarge = entry.ImagePath + '?h=750&w=960';
				defaults.boxValues.CVR = entry.Cvr;

				//further up the code entry.parsedBankruptcyDate will be set to null if it is not proper data
				if (entry.parsedBankruptcyDate !== null){
					defaults.boxValues.DateOfBankruptcyFormatted = entry.parsedBankruptcyDate.date.day + "." + entry.parsedBankruptcyDate.date.month + "." + entry.parsedBankruptcyDate.date.year.toString().substr(2);
					defaults.boxValues.TimestampOfBankruptcy = entry.parsedBankruptcyDate.timestamp;
					defaults.boxValues.YearOfBankruptcy = entry.parsedBankruptcyDate.date.year;
				} else {
					defaults.boxValues.DateOfBankruptcyFormatted = defaults.boxValues.missingContentLabel;
					defaults.boxValues.TimestampOfBankruptcy = defaults.boxValues.missingContentLabel;
					defaults.boxValues.YearOfBankruptcy = defaults.boxValues.missingContentLabel;
				}

				//these values are set to "ukendt" if there are no
				if(!entry.CompanyName || entry.CompanyName === ""){
					defaults.boxValues.CompanyName = defaults.boxValues.missingContentLabel;
				} else {
					defaults.boxValues.CompanyName = entry.CompanyName;
				}
				if(!entry.ConcernedCitizensTotal || entry.ConcernedCitizensTotal === ""){
					defaults.boxValues.ConcernedCitizensTotal = defaults.boxValues.missingContentLabel;
				} else {
					defaults.boxValues.ConcernedCitizensTotal = entry.ConcernedCitizensTotal;
				}
				if(!entry.ConcernedCitizensCounty || entry.ConcernedCitizensCounty === ""){
					defaults.boxValues.ConcernedCitizensCounty = defaults.boxValues.missingContentLabel;
				} else {
					defaults.boxValues.ConcernedCitizensCounty = entry.ConcernedCitizensCounty;
				}
				if(!entry.ConcernedEmployeesTotal || entry.ConcernedEmployeesTotal === ""){
					defaults.boxValues.ConcernedEmployeesTotal = defaults.boxValues.missingContentLabel;
				} else {
					defaults.boxValues.ConcernedEmployeesTotal = entry.ConcernedEmployeesTotal;
				}
				if(!entry.County || entry.County === ""){
					defaults.boxValues.County = defaults.boxValues.missingContentLabel;
				} else {
					defaults.boxValues.County = entry.County;
				}

				defaults.boxValues.CountyId = entry.CountyId;
				defaults.boxValues.RegionId = entry.RegionId;
				defaults.boxValues.IsFocusBankruptcy = entry.IsFocusBankruptcy;


				//boxhtml is added to boxarray, so we have all the html to each box in an object with the key itemId
				eventHandlers.createBoxDom();
				totalBoxObjects[entry.ItemId].push(boxDom);

				//create box
				//for each box that is in the current selection - display
				eventHandlers.createBox(entry.ItemId);
			});

			activeYears.sort().reverse();
			underscore.each(activeYears, function(year) {
				dom.filterSelectors.period.append('<option value="' + year + '">' + year + '</option>');
			});
			dom.filterSelectors.period.data("selectBox-selectBoxIt").refresh();

			//the underscore.uniq function removes duplicates from the array
			activeMunicipalities = underscore.uniq(activeMunicipalities);

			underscore.each(municipalitiesInRegion, function(list, key) {
				//list - CountyId
				//key - RegionId

				municipalitiesInRegion[key] = underscore.uniq(list);
			});

			dom.filterSelectors.municipalities.find('option').each(function () {
				var obj = $(this),
					val = obj.attr('value');

				//checks if option has a value and if the value is one of the activeMunicipalities. If not then the option is removed from select
				//TODO: should the option be disabled instead of removed?
				if(val && underscore.indexOf(activeMunicipalities, val) === -1){
					obj.remove();
				}
			});

			dom.filterSelectors.municipalities.data("selectBox-selectBoxIt").refresh();

			//draw graph with all regions selected
			eventHandlers.charts.regions();

			// Use imagehandler on boxes
			imagehandler = new dis.ImageHandler();
		}

		function getData() {
			// Function for getting the data for charts via AJAX
			//on load success the data is taken care of in the parseData function

			// Send AJAX request
			$.ajax(configuration.ajaxUrl, {
				method: "GET",
				cache: false,
				success: parseData,
				error: function (jqXHR, textStatus, errorThrown) {
					// Error - this should never happen
					window.console.log("Error occured:");
					window.console.log(jqXHR);
					window.console.log(textStatus);
					window.console.log(errorThrown);
				}
			});
		}

		eventHandlers = {
			createBoxDom: function(){
				//if bankruptcy should be highlighted we add a certain css class to display the box differently
				var isFocusClass;
				if(defaults.boxValues.IsFocusBankruptcy){
					isFocusClass = "result--focus";
				} else {
					isFocusClass = "";
				}

				boxDom = '<li class="'+ isFocusClass +'" data-cvr="' + defaults.boxValues.CVR + '" data-concerned-employees="' + defaults.boxValues.ConcernedEmployeesTotal + '" data-concerned-citizens="' + defaults.boxValues.ConcernedCitizensTotal + '" data-timestamp="' + defaults.boxValues.TimestampOfBankruptcy + '" data-year="' + defaults.boxValues.YearOfBankruptcy + '" data-id="' + defaults.boxValues.itemId + '" data-municipality="' + defaults.boxValues.CountyId + '" data-region="' + defaults.boxValues.RegionId + '">' +
					'<div class="bg-imagehandler" data-id="' + defaults.boxValues.itemId + '" data-src-xs="' + defaults.boxValues.imgSmall + '" data-src-md="' + defaults.boxValues.imgLarge + '"></div>' +
					'<dl class="result__date hidden-extended"><dt>' + defaults.boxValues.DateOfBankruptcyFormatted + '</dt><dd>Konkurs</dd></dl>' +
					'<dl class="result__company hidden-extended"><dt>' + defaults.boxValues.CompanyName + '</dt><dd>Firma</dd></dl>' +
					'<div class="result__flexcontainer">' +
						'<dl class="result__citicens"><dt><span class="line-height--lg">' + defaults.boxValues.ConcernedCitizensTotal + '</span><span class="visible-municipality line-height--sm"><strong>' + defaults.boxValues.ConcernedCitizensCounty + '</strong> i ' + defaults.boxValues.County + '</span></dt><dd>Berørte borgere</dd></dl>' +
					'</div>' +
					'<div class="result__flexcontainer visible-extended">' +
						'<dl class="result__employees"><dt><span class="line-height--lg">' + defaults.boxValues.ConcernedEmployeesTotal + '</span><span class="visible-municipality line-height--sm"><strong>' + defaults.boxValues.ConcernedEmployeesCounty + '</strong> i ' + defaults.boxValues.County + '</span></dt><dd>Berørte ansatte</dd></dl>' +
					'</div>' +
					'<div class="extend__icon"><svg role="img"><use xlink:href="/static/dist/icons.svg#arrow-down--21x12"></use></svg></div>' +
					'</li>'
				;
			},
			createBox: function(itemId){
				var ul = $(".results > ul");

				ul.prepend(
					totalBoxObjects[itemId]
				);
			},
			clickBox: function () {
				var bankruptcyId = $(this).data("id"),
					bankruptcyCVR = $(this).data("cvr"),
					shareImgSrc =  $(this).find('.bg-imagehandler').attr('data-src-md'),
					bankruptcyData = underscore.find(masterData, function (item) {
						return item.ItemId === bankruptcyId;
					}),
					links = $(bankruptcyData.Links);

				dom.extendedInfoLi.attr('data-id' , bankruptcyId);
				dom.extendedInfoLi.attr('data-cvr' , bankruptcyCVR);
				dom.extendedInfoLi.attr('data-shareimgsrc' , shareImgSrc);

				dom.extendedInfoH2.text(bankruptcyData.CompanyName);
				dom.extendedInfoH3.text(bankruptcyData.CompanyName);
				dom.extendedInfoDescription.html(bankruptcyData.Resume);
				dom.extendedInfoAddress.html(bankruptcyData.Address);
				dom.extendedInfoStartDate.html(bankruptcyData.parsedFoundedDate.day + "." + bankruptcyData.parsedFoundedDate.month + "." + bankruptcyData.parsedFoundedDate.year.toString().substr(2));
				dom.extendedInfoCvr.html(bankruptcyData.Cvr);
				if(bankruptcyData.ConcernedCitizensTotal || bankruptcyData.ConcernedCitizensTotal !== ""){
					dom.extendedInfoAffectedCitizins.html(bankruptcyData.ConcernedCitizensTotal);
				} else {
					dom.extendedInfoAffectedCitizins.html(defaults.boxValues.missingContentLabel);
				}
				dom.extendedInfoBankruptcyDate.html(bankruptcyData.parsedBankruptcyDate.date.day + "." + bankruptcyData.parsedBankruptcyDate.date.month + "." + bankruptcyData.parsedBankruptcyDate.date.year.toString().substr(2));
				if(bankruptcyData.Owner || bankruptcyData.Owner !== ""){
					dom.extendedInfoOwner.html(bankruptcyData.Owner);
				} else {
					dom.extendedInfoOwner.html(defaults.boxValues.missingContentLabel);
				}
				if(bankruptcyData.ConcernedEmployeesTotal || bankruptcyData.ConcernedEmployeesTotal !== ""){
					dom.extendedInfoEmployees.html(bankruptcyData.ConcernedEmployeesTotal);
				} else {
					dom.extendedInfoEmployees.html(defaults.boxValues.missingContentLabel);
				}
				dom.extendedInfoAffectedMunicipalities.html(bankruptcyData.AffectedCounties.join("<br>"));
				dom.extendedInfoRegion.html(bankruptcyData.AffectedRegions.join("<br>"));

				// Only show links column if there are links to show
				if (links.length) {
					dom.extendedInfoLinksParent.css("opacity", 1);
				} else {
					dom.extendedInfoLinksParent.css("opacity", 0);
				}

				// Empty list of links and put in the new links
				dom.extendedInfoLinks.empty();
				links.each(function (index, link) {

				dom.extendedInfoLinks.append('<li><a href="' + link.Url + '" title="' + link.Title + '" target="' + link.Target + '">' + link.Text + '<span class="icon-after"><svg role="img"><use xlink:href="/static/dist/icons.svg#arrow-right--10x17"></use></svg></span></a></li>');
				});
			},
			sortBoxes: function (sortingFunction, elements) {
				var list,
					boxes,
					sortedBoxes;

				if (elements) {
					list = elements.parent();
					boxes = elements;
					sortedBoxes = elements.sort(sorting[sortingFunction]);
				} else {
					list = $(".results > ul");
					boxes = list.find("> li:not(.extended-view)");
					sortedBoxes = boxes.sort(sorting[sortingFunction]);
				}

				boxes.remove();

				sortedBoxes.each(function () {
					list.append(this);
				}).click(eventHandlers.clickBox);

				return sortedBoxes;
			},
			displayBoxes: function() {

				//filter select is disabled / enabled while displaying elements
				dom.sortingSelect.data("selectBox-selectBoxIt").disable();

				var timeoutMS = 0;
				if (filteringIteration > 1) {
					dom.resultsLoader.addClass("loading");
					timeoutMS = 500;
				}

				setTimeout(function () {
					if (filteringIteration > 1) {
						setTimeout(function () {
							dom.resultsLoader.removeClass("loading");
						}, 1500);
					}

					var list = $(".results > ul");
					var boxes = list.find("> li:not(.extended-view)");

					var totalBoxes = boxes.length;
					var sortedBoxes = eventHandlers.sortBoxes("latestBankruptcy", boxes);
					var cvrs = {};

					//if municipality is selected we sort through all boxes even with same cvr nr.
					for (var i = 0; i < totalBoxes; i += 1) {
						var thisBox = sortedBoxes.eq(i),
							customDelay = i * 0.1,
							thisCVR = thisBox.data("cvr");

						if(!configuration.municipality && cvrs[thisCVR]){
							thisBox.css({
								'display': 'none',
								'visibility': 'visible',
								'opacity': 1
							});
						} else if ((configuration.municipality && configuration.municipality === thisBox.data("municipality")) && (!configuration.showYear || configuration.showYear === thisBox.data("year"))) {
							// Show boxes in selected municipality
							thisBox.css({
								'display': 'block',
								'visibility': 'visible',
								'opacity': 1
							});
							cvrs[thisCVR] = true;
						} else if ((!configuration.municipality && configuration.region && configuration.region === thisBox.data("region")) && (!configuration.showYear || configuration.showYear === thisBox.data("year"))) {
							// Show boxes in selected region
							thisBox.css({
								'display': 'block',
								'visibility': 'visible',
								'opacity': 1
							});
							cvrs[thisCVR] = true;
						} else if (!configuration.municipality && !configuration.region && configuration.showYear && configuration.showYear === thisBox.data("year")) {
							// Show boxes in selected municipality
							thisBox.css({
								'display': 'block',
								'visibility': 'visible',
								'opacity': 1
							});
							cvrs[thisCVR] = true;
						} else if (configuration.municipality || configuration.region || configuration.showYear) {
							// Hide shit
							thisBox.css({
								'display': 'none',
								'visibility': 'visible',
								'opacity': 1
							});
						} else {
							thisBox.css('display', 'block');
							TweenMax.to(thisBox, 0.3, {autoAlpha: 1, delay: customDelay, ease: Power2.easeOut});
							cvrs[thisCVR] = true;
						}
					}

					//filter select is disabled / enabled while displaying elements
					dom.sortingSelect.data("selectBox-selectBoxIt").enable();
				}, timeoutMS);
			},
			createChart: function (type, data, options) {
				if (chart) {
					// If a chart already exists, remove it before creating a new one
					eventHandlers.replaceChart(type, data, options);

					console.log("chart is true");
					return;
				}

				options = options || {};
				options = $.extend(defaults.chartOptions, options);
				chart = new window.Chartist[type](configuration.container, data, options);

				// Reset number of draws
				chartDrawsCount = 0;

				// Start animations if they are supported
				if (window.Modernizr.cssanimations) {
					dom.container.addClass(configuration.classNames.animateIn);
				}

				//update numbers
				console.log("chartDrawsCount: " + chartDrawsCount);
				eventHandlers.updateNumbers(totalBankruptcies,totalCitizens,totalEmployees);

				// This runs on creation and resizing
				chart.on("created", function () {
					// Add class to container telling that the chart has been drawn
					dom.container.addClass("drawn");

					// Count number of draws since creation
					chartDrawsCount += 1;

					// Stop animations if this is a redraw (like when resizing the window)
					// and if CSS animations are supported
					if (window.Modernizr.cssanimations && chartDrawsCount > 1) {
						dom.container.removeClass(configuration.classNames.animateIn);
					}
				});

				// This runs on the drawing of every single element in the chart
				chart.on("draw", function (drawData) {
					if ((drawData.type === "point" && drawData.value.y > 0 || drawData.type === "bar")) {
						var pointID = "chart-point-" + drawData.index,
							point = $("#" + pointID),
							pointXpos = (drawData.type === "point" ? drawData.x : drawData.x1),
							pointYpos = (drawData.type === "point" ? drawData.y : drawData.y2),
							pointLabel;

						if (point.length) {
							// Point already exists - update it!
							point.css({
								left: pointXpos,
								top: pointYpos
							});
						} else {
							// Point does not exist - create it!
							pointLabel = drawData.axisX.ticks[drawData.index];
							$("<div />", {
								"class": configuration.classNames.pointDefault + (isNaN(drawData.value.y) ? " " + configuration.classNames.pointBullet : "") + (data.filter ? " " + configuration.classNames.pointClickable : ""),
								"id": pointID,
								"text": drawData.value.y,
								"data-label": pointLabel,
								"click": (!data.filter ? null : function () {
									// Change filter
									eventHandlers.updateFilter(data.filter, pointLabel);
								})
							}).css({
								left: pointXpos,
								top: pointYpos
							}).appendTo(dom.container);
						}
					}
				});
			},
			updateFilter: function (filterName, selectedValue) {
				if (filterName !== "bankruptcies") {
					// Regions and municipalities
					var selectedId = selectBoxValues[filterName][selectedValue];
					dom.filtersWrapper.find("select[data-chart=" + filterName + "]").val(selectedId).change();
				}
			},
			updatePeriod: function (year) {
				if (year !== undefined) {
					// Change period to show on charts
					configuration.showYear = year || undefined;

					// Update period in currently active chart
					if (!configuration.region && !configuration.municipality) {
						eventHandlers.charts.regions();
					} else if (configuration.region && !configuration.municipality) {
						eventHandlers.charts.municipalities();
					} else {
						eventHandlers.charts.bankruptcies();
					}
				} else {
					// Error
					window.console.log("eventHandlers.updatePeriod() needs an object with the keys 'from' and 'to'");
				}
			},
			replaceChart: function (type, data, options) {
				var points = dom.container.find("." + configuration.classNames.pointDefault),
					chartHeight = dom.container.height();

				// Remove reference to chart
				chart = null;

				// If CSS animations are supported, run that shizzle
				if (window.Modernizr.cssanimations) {
					dom.container.addClass(configuration.classNames.animateOut).on("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function (event) {
						if ($(event.target).hasClass(configuration.container.substr(1))) {
							// Remove any listeners from container (e.g.: animationend)
							dom.container.off();

							// Remove points
							points.remove();

							// Resize if needed
							data.height = !isNaN(data.height) ? data.height : configuration.chartOptions.defaultHeight;
							if (data.height !== chartHeight) {
								// Resize
								dom.container.css({
									minHeight: data.height,
									maxHeight: data.height
								}).on("transitionend transitionEnd webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
									// Remove any listeners from container (e.g.: transitionend)
									dom.container.off();

									if (data.height === 0) {
										// If height is 0, animations should be quick
										dom.container.addClass(configuration.classNames.animateQuick);
									} else {
										// If height is more than 0, animations should be not quick
										dom.container.removeClass(configuration.classNames.animateQuick);
									}

									// Remove animate-out class
									dom.container.removeClass(configuration.classNames.animateOut);

									options = options || {};
									options.height = data.height + "px";

									// Create new chart
									eventHandlers.createChart(type, data, options);
								});
							} else {
								// Don't resize

								// Remove animate-out class
								dom.container.removeClass(configuration.classNames.animateOut);

								// Create new chart
								eventHandlers.createChart(type, data, options);
							}
						}
					});
				} else {
					// Remove points
					points.remove();

					// Create new chart
					eventHandlers.createChart(type, data, options);
				}
			},

			charts: {
				regions: function () {
					// Build chart with region data
					//------------------------------

					// Get filtered data
					filterData(
						function (data) {
							// Process received data, so that the 1st, 3rd, 5th (and so on) values/labels are empty
							var processedData = {
									filter: "regions",
									labels: [],
									series: [[]]
								},
								i = 0,
								l = data.labels.length;

							for (; i < l; i += 1) {
								// Push new labels to array with processed data
								// First label is empty
								processedData.labels.push("");
								processedData.labels.push(data.labels[i]);

								// Push new values to array with processed data
								// First value is empty
								processedData.series[0].push(0);
								processedData.series[0].push(data.series[0][i]);
							}

							// The last label and value must also be empty
							processedData.labels.push("");
							processedData.series[0].push(0);

							// Create line chart from processed data
							eventHandlers.createChart("Line", processedData);
						}
					);
				},
				municipalities: function () {
					// Build chart with municipalities data
					//--------------------------------------

					// Get data
					filterData(
						function(data) {
							data.filter = "municipalities";
							// Create bar chart from received data
							eventHandlers.createChart("Bar", data);
						}
					);
				},
				bankruptcies: function () {
					// Build chart with bankruptcies data
					//------------------------------------

					// Get data
					filterData(
						function(data) {
							// Process received data by adding empty values/labels to represent
							// the time between the dates of the points on the chart
							var processedData = {
									height: 0,
									filter: "bankruptcies",
									labels: [],
									series: [[]]
								},
								i = 0,
								l = data.labels.length;

							for (; i < l; i += 1) {
								// Push labels to array with processed data
								processedData.labels.push(data.labels[i]);

								// Push new values to array with processed data
								// The dash makes the point visible but without a text
								processedData.series[0].push("-");
							}

							// Create chart from processed data
							eventHandlers.createChart("Bar", processedData);
						}
					);
				}
			},
			updateNumbers: function(totalNum, citizenNum, employeesNum) {
				//update total number of citizens
				if(citizenNum !== undefined) {
					var citizens = $(".numbers__citizens .numbers__amount");
					var counterCitizens = {var: citizens.text()};
					TweenMax.to(counterCitizens, defaults.numberOptions.animationDuration, {
						var: citizenNum,
						delay: 0,
						onUpdate: function () {
							citizens.text(Math.ceil(counterCitizens.var));
						},
						ease: Power2.easeInOut
					});
				}

				//update total number of employees
				if (employeesNum !== undefined) {
					var employees = $(".numbers__employees .numbers__amount");
					var counterEmployees = {var: employees.text()};
					TweenMax.to(counterEmployees, defaults.numberOptions.animationDuration, {
						var: employeesNum,
						delay: 0,
						onUpdate: function () {
							employees.text(Math.ceil(counterEmployees.var));
						},
						ease: Power2.easeInOut
					});
				}

				//update total number of bankrupts
				if (totalNum !== undefined) {
					var total = $(".numbers__total .numbers__amount");
					var counterTotal = {var: total.text()};
					TweenMax.to(counterTotal, defaults.numberOptions.animationDuration, {
						var: totalNum,
						delay: 0,
						onUpdate: function () {
							total.text(Math.ceil(counterTotal.var));
						},
						onComplete: function () {
							if (filteringIteration === 1) {
								eventHandlers.displayBoxes();
							}
						},
						ease: Power2.easeInOut
					});
				}
			}
		};


		sorting = {
			alphabetically: function (a, b) {
				var aStr = $(a).find(".result__company dt").text().toLowerCase(),
					bStr = $(b).find(".result__company dt").text().toLowerCase();

				return (aStr < bStr) ? -1 : (aStr > bStr) ? 1 : 0;
			},
			mostConcernedCitizens: function (a, b) {
				var aNum = $(a).data("concernedCitizens"),
					bNum = $(b).data("concernedCitizens");

				aNum = (isNaN(aNum) ? 0 : aNum);
				bNum = (isNaN(bNum) ? 0 : bNum);

				return bNum < aNum ? -1 : bNum > aNum ? 1 : 0;
			},
			mostConcernedEmployees: function (a, b) {
				var aNum = $(a).data("concernedEmployees"),
					bNum = $(b).data("concernedEmployees");

				aNum = (isNaN(aNum) ? 0 : aNum);
				bNum = (isNaN(bNum) ? 0 : bNum);

				return bNum < aNum ? -1 : bNum > aNum ? 1 : 0;
			},
			latestBankruptcy: function (a, b) {
				var aNum = $(a).data("timestamp"),
					bNum = $(b).data("timestamp");

				return bNum < aNum ? -1 : bNum > aNum ? 1 : 0;
			}
		};


		handlers = {
			randomIntFromInterval: function(min,max)
			{
				return Math.floor(Math.random()*(max-min+1)+min);
			}
		};

		/**
		 * Initialization function, which is run when the module is "booting".
		 */
		function init() {
			dom.container = $(configuration.container);

			if (dom.container.length) {
				dom.filtersWrapper = $(configuration.filtersWrapper);

				dom.resultsLoader = $(".results-loader");

				dom.extendedInfoLi = $(".extended-view");
				dom.extendedInfoH2 = dom.extendedInfoLi.find(".extended-view__top h2");
				dom.extendedInfoH3 = dom.extendedInfoLi.find(".extended-view__bottom h3");
				dom.extendedInfoDescription = dom.extendedInfoLi.find(".content-container--66");
				dom.extendedInfoAddress = dom.extendedInfoLi.find(".extended-view__bottom .address");
				dom.extendedInfoStartDate = dom.extendedInfoLi.find(".extended-view__bottom .start-date");
				dom.extendedInfoCvr = dom.extendedInfoLi.find(".extended-view__bottom .cvr");
				dom.extendedInfoAffectedCitizins = dom.extendedInfoLi.find(".extended-view__bottom .affected-citizens");
				dom.extendedInfoAffectedMunicipalities = dom.extendedInfoLi.find(".extended-view__bottom .affected-municipalities");
				dom.extendedInfoBankruptcyDate = dom.extendedInfoLi.find(".extended-view__bottom .bankruptcy-date");
				dom.extendedInfoOwner = dom.extendedInfoLi.find(".extended-view__bottom .owner");
				dom.extendedInfoEmployees = dom.extendedInfoLi.find(".extended-view__bottom .employees");
				dom.extendedInfoRegion = dom.extendedInfoLi.find(".extended-view__bottom .region");
				dom.extendedInfoLinksParent = dom.extendedInfoLi.find(".content-container--33");
				dom.extendedInfoLinks = dom.extendedInfoLinksParent.find(".link-list");

				dom.sortingSelect = $("#results__top__select");

				// Set max-height on container and remember it as the default
				dom.container.css({
					minHeight: configuration.chartOptions.height,
					maxHeight: configuration.chartOptions.height
				});
				configuration.chartOptions.defaultHeight = parseInt(configuration.chartOptions.height);

				dom.filterSelectors = {};
				dom.filtersWrapper.find('select').each(function () {
					var obj = $(this),
						filterName = obj.attr('data-chart'),
						options = {};

					obj.find('option').each(function(){
						var opt = $(this),
							value = opt.attr('value'),
							label = opt.text();
						options[label] = value;
					});

					dom.filterSelectors[filterName] = obj;

					selectBoxValues[filterName] = options;
				});

				/*
				* Date is loaded once and for all on page init, and by each change filtered to displayed the appropriate bankcrupties
				* */
				getData();

				// FILTERS CHANGES
				//-----------------

				// Listen for changes to period filter
				dom.filterSelectors.period.on("change", function () {
					var filter = $(this),
						value = parseInt(filter.val());

					// Update period in current chart
					eventHandlers.updatePeriod(value);
				});

				// Listen for changes to region filter
				dom.filterSelectors.regions.on("change", function () {
					var filter = $(this),
						region = filter.val().replace(/[{}]/g, ""),//the values in selects are wrapped in {}, which we must remove to be able to compare values when filtering
						selectBoxIt = dom.filterSelectors.municipalities.data("selectBox-selectBoxIt");

					if (region) {
						// Remember selected region
						configuration.region = region;

						// Create chart of municipalities in selected region
						if (!configuration.municipality) {
							eventHandlers.charts.municipalities();
						}

						dom.filterSelectors.municipalities.find('option').each(function (i) {
							var obj = $(this),
								val = obj.attr('value').replace(/[{}]/g, ""); //the values in selects are wrapped in {}, which we must remove to be able to compare values when filtering

							if(val && underscore.indexOf(municipalitiesInRegion[region], val) === -1){
								//we are using selectBoxIts own method to disable and enable option. This combined with css makes sure that only relevant options are displayed for the user
								//fx. only municipalities in the selected region
								selectBoxIt.disableOption(i);
							} else {
								selectBoxIt.enableOption(i);
							}
						});

						if (configuration.municipality) {
							configuration.municipality = "";
							dom.filterSelectors.municipalities.val("").change();
						}
					} else {
						// Forget previously selected region
						configuration.region = "";
						configuration.municipality = "";

						// Create chart of all regions
						eventHandlers.charts.regions();

						dom.filterSelectors.municipalities.find('option').each(function (i) {
							selectBoxIt.enableOption(i);
						});
						dom.filterSelectors.municipalities.val("").data("selectBox-selectBoxIt").refresh();
					}
				});

				// Listen for changes to region filter
				dom.filterSelectors.municipalities.on("change", function () {
					var filter = $(this),
						municipality = filter.val().replace(/[{}]/g, "");//the values in selects are wrapped in {}, which we must remove to be able to compare values when filtering

					if (municipality) {
						// Remember selected municipality
						configuration.municipality = municipality;

						// Create chart of bankruptcies in selected municipality
						eventHandlers.charts.bankruptcies();

					} else {
						// Forget previously selected municipality
						configuration.municipality = "";

						// Create chart of all municipalities
						if (configuration.region) {
							eventHandlers.charts.municipalities();
						} else {
							eventHandlers.charts.regions();
						}

					}
				});

				// Sorting

				dom.sortingSelect.change(function () {
					var sortingMethod = $(this).val();

					eventHandlers.sortBoxes(sortingMethod);
				});
			}
		}

		selfScope.onReady(init);

	};

	dis.Chart.prototype = new dis.BaseModule();
	dis.Chart.constructor = dis.Chart;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis, _));